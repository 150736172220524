import { apiSlice } from "./apiSlice";

export const publicApiSlice = apiSlice.injectEndpoints({
  tagTypes: [
    "Otp",
    "Register",
    "PublicBonusList",
    "PublicBonusDetails",
    "CommonBackground",
    "DepositCategory",
    "ForgotPassword",
    "ResetPassword",
    "Language",
  ],
  endpoints: (builder) => ({
    getOtp: builder.query({
      query: (recipient) => ({
        url: `public/api/v1/getOtp?recipient=${recipient}`,
      }),
      providesTags: ["Otp"],
    }),
    registerAccount: builder.mutation({
      query: (account) => ({
        url: "/public/api/v1/register",
        method: "POST",
        body: account,
      }),
      providesTags: ["Register"],
      invalidatesTags: ["Profile", "BonusList", "GameCategory"],
    }),
    getPublicBonusList: builder.query({
      query: (category) => ({
        url: `public/api/v1/bonusList?category=${category}`,
      }),
      providesTags: ["PublicBonusList"],
    }),
    getPublicBonusDetails: builder.query({
      query: (bonusCode) => `/public/api/v1/bonusDetails/${bonusCode}`,
      providesTags: ["PublicBonusDetails"],
    }),
    getCommonBackground: builder.query({
      query: () => ({
        url: `public/api/v1/commonBackground`,
      }),
      providesTags: ["CommonBackground"],
    }),
    getDepositCategory: builder.query({
      query: () => ({
        url: `public/api/v1/depositCategory`,
      }),
      providesTags: ["DepositCategory"],
    }),
    getForgotPassword: builder.query({
      query: (email) => ({
        url: `public/api/v1/forgotPassword?email=${email}`,
      }),
      providesTags: ["ForgotPassword"],
    }),
    resetPassword: builder.mutation({
      query: (resetDetails) => ({
        url: `public/api/v1/resetPassword`,
        method: "POST",
        body: resetDetails,
      }),
      providesTags: ["ResetPassword"],
    }),
    getLanguages: builder.query({
      query: () => ({
        url: `public/api/v1/languages`,
      }),
      providesTags: ["Language"],
    }),
  }),
});

export const {
  useLazyGetOtpQuery,
  useRegisterAccountMutation,
  useLazyGetPublicBonusListQuery,
  useLazyGetPublicBonusDetailsQuery,
  useGetCommonBackgroundQuery,
  useGetDepositCategoryQuery,
  useLazyGetForgotPasswordQuery,
  useResetPasswordMutation,
  useGetLanguagesQuery,
} = publicApiSlice;
